<script lang="jsx">
import BaseImg from '../../base/BaseImg.vue'
import HotZone from './HotZone.vue'
import FloatStyle from './FloatStyle.vue'
import SuperWide from './SuperWide.vue'
import SuperWideLoop from './SuperWideLoop.vue'

import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import { useCommonMixin } from 'public/src/pages/components/ccc/common/use-common-mixin'
import InvokeApp from 'public/src/pages/components/ccc/base/InvokeApp.vue'
import { isUnDef } from '@shein/common-function'

// 根据横滑类型映射图片样式类型
const COMPONENT_TYPE = {
  // dynamic
  ONE_IMAGE_COMPONENT: 'one',
  TWO_IMAGE_COMPONENT: 'two',
  THREE_IMAGE_COMPONENT: 'three',
  FOUR_IMAGE_COMPONENT: 'four'
}


export default {
  name: 'StaticImage',
  functional: true,
  props: commonProps,
  render(h, { props, listeners, data }) {
    const { propData, sceneData, cateLinks, index, context } = props
    const {
      props: propDataProps = {},
      hotZones = {},
      autoSlide = {},
      styleType,
      id = '',
      isFirstPage = false,
    } = propData || {}
    const { items = [], metaData = {} } = propDataProps
    const {
      disableBottomSpacing = '1',
      intervalSpacing = '0',
      enableFloatDisplayAfterReceive = false, // 是否允许底部悬浮
    } = metaData
    // 单图底部悬浮
    const isBottomFloat = data.attrs?.isBottomFloat || false
    const isEnableFloat = styleType === 'ONE_IMAGE_COMPONENT' && enableFloatDisplayAfterReceive
    const type =  COMPONENT_TYPE[styleType] || ''

    const bottomStickyParams = {
      isAllowFloat: isEnableFloat,
      styleType,
      compId: id,
      isFirstScreen: isFirstPage,
    }
    // 目前只有静态单图支持配置热区
    const showHotZone = Boolean(
      styleType === 'ONE_IMAGE_COMPONENT' &&
        (hotZones?.standard?.length || hotZones?.timeTransform?.length || hotZones?.label?.length)
    )

    // 是否悬浮图样式
    const isFloatStyle = styleType === 'FLOW_IMAGE_COMPONENT'

    // 是否超宽图 - 动作设置（目前仅支持静态单图）
    const showSuperWide = styleType === 'ONE_IMAGE_COMPONENT' && (autoSlide?.isAutoSlide)

    // 超宽且循环
    const showSuperWideLoop = showSuperWide && (autoSlide?.loopSlide)

    // 展示图类型（默认常规静态单图）
    const styleCase = showSuperWide ? 'superWide' : (isFloatStyle ? 'floatStyle' : '')

    // 获取原来mixin中的数据和方法
    const { cccLink, jumpLink, getAnalysisData } = useCommonMixin({ propData, sceneData, index })

    const getImgDesignWidth = () => {
      if (items.length) {
        return Math.floor(750 / items.length)
      }
      return 750
    }

    const baseImageMounted = () => {
      listeners?.baseImageMounted?.()
    }

    const clickItem = (item, i) => {
      // 跳转商详需要重置缓存
      window?._gb_app_?.$store?.commit('addProductDetailBfCache', { goods_lazy_img: context?.LAZY_IMG }, { root: true })
      const url = cccLink.getFullLink({
        item,
        cateLinks,
        compIndex: index,
        isBff: true,
        index: i,
      })

      jumpLink({ url, hrefType: item.hrefType })
    }
    const isCardStyle = metaData?.isCardShow  == '1'

    const calcStyle = (index, array) => {
      let len = array?.length
      let result = {
        width: '100%'
      }
      // 单个图片 时 左右无边距
      if (len === 1) {
        result.marginLeft = 0
        result.marginRight = 0
      } else if (len > 1) {
        const { GB_cssRight = false } =
          typeof window === 'undefined' ? {} : gbCommonInfo
        const rtl = GB_cssRight
        let attr = 'marginLeft'
        if (index === 0) {
          attr = rtl ? 'marginRight' : 'marginLeft'
          result[attr] = 0
        } else if (index === len - 1) {
          attr = rtl ? 'marginLeft' : 'marginRight'
          result[attr] = 0
        }
      }
      const cardStyle = isCardStyle ? cardImgRadiusStyle()(index) : {}
      return Object.assign({}, result, cardStyle)
    }

    // 增加卡片化样式
    const cardStyle = () => {
      let { 
        margin = [], 
        isCardShow = false 
      } = metaData || {}

      isCardShow = Number(isCardShow)

      // 静态组件: 没有开启全局配置卡片化时修改兜底样式margin：[0, 8, 10, 8] ，raius：3
      if(isCardShow != '1'){
        margin = [0, 8, 10, 8]
      }
      
      const marginList =
        Array.isArray(margin) && margin.length === 4
          ? margin.slice()
          : Array(4).fill(0)
      const marginStyle = marginList
        .map((item) => `${Number(item)}px`)
        .join(' ')
      const styleResult = {
        margin: marginStyle
      }

      return styleResult
    }
    // case1: 有间隙 - 全圆角
    // case2: 无间隙有标题 - 每行首尾圆角
    // case3: 无间隙无标题 - 整体圆角
    const cardImgRadiusStyle = () => {
      return staticCardImgRadiusStyle()
    }
    // 静态组件卡片化图片圆角样式
    const staticCardImgRadiusStyle = () => {
      return (i) => {
        let styleResult = {}
        const { start, end, all } = staticCardImgRadiusStyleMap()
        const typeMapping = {
          'one': all,
          'two': i % 2 === 0 ? start : (i % 2 === 2 - 1 ? end : {}),
          'three': i % 3 === 0 ? start : (i % 3 === 3 - 1 ? end : {}),
          'four': i % 4 === 0 ? start : (i % 4 === 4 - 1 ? end : {})
        }

        // 图片有间隙
        if(intervalSpacing == '1' && type != 'one'){
          styleResult = isUnDef(i) ? {} : all
        }else {
          styleResult = typeMapping[type] || {}
        }
        return styleResult
      }
    }
    const staticCardImgRadiusStyleMap = () =>{
      const { cardRadius = 3 } = metaData || {}
      const radius = `${Number(cardRadius)}px`
      const start = `${radius} 0 0 ${radius}`
      const end = `0 ${radius} ${radius} 0`
      return {
        start: {
          borderRadius: start,
        },
        end: {
          borderRadius: end,
        },
        all: {
          borderRadius: radius,
        },
      }
    }

    return (
      <div
        class="static-image__wrapper"
        ref="staticImageRef"
        v-bottom-sticky={bottomStickyParams}
        style={isBottomFloat ? 'width: 100%' : '', isCardStyle ? cardStyle() : ''}
      >
        <div
          class={[
            'static-image',
            disableBottomSpacing != '1' ? 'hasBottomSpace' : '',
            showHotZone ? 'full-width' : '',
          ]}
          style={isCardStyle ? cardImgRadiusStyle()() : ''}
        >
          {
            (() => {
              switch(styleCase) {
                /* 超宽图样式 */
                case 'superWide': 
                  return items.map(item => (
                    showSuperWideLoop ? <SuperWideLoop
                      {...{ props: props }}
                      item={item}
                      showHotZone={showHotZone}
                      vOn:click={clickItem}
                    /> : <SuperWide
                      {...{ props: props }}
                      item={item}
                      showHotZone={showHotZone}
                      vOn:click={clickItem}
                    />
                  ))
                  /* 悬浮图样式 */
                case 'floatStyle': 
                  return <FloatStyle {...{ props: props }} vOn:click={clickItem} />
                  /* 默认样式 */
                default: 
                  return ([
                    items.map((item, i) => (
                      <InvokeApp
                        class={[ intervalSpacing == '1' ? 'intervalSpacing' : '']}
                        style={calcStyle(i, items)}
                        deeplink-source={item.hrefTarget}
                        is-direct-store={true}
                        is-deeplink-style-string={true}
                        is-invoke={item.hrefTarget?.indexOf?.('sheinlink') > -1}
                      >
                        {
                          <BaseImg
                            key={i}
                            v-tap={getAnalysisData('2-22-2', { item, index: i, useBffApi: true })}
                            v-expose={getAnalysisData('2-22-1', { item, index: i, useBffApi: true })}
                            style={calcStyle(i, items)}
                            placeholder={{
                              width: item.image && item.image.width,
                              height: item.image && item.image.height,
                            }}
                            ratio={item.image && item.image.ratio}
                            brand={props.brand}
                            img-src={item.image && item.image.src}
                            img-design-width={getImgDesignWidth()}
                            first-screen={propData.isFirstPage}
                            ada={item.ada}
                            alt={item.hrefTitle}
                            comp-src={'static-image'}
                            vOn:click={() => {
                              if (item.hrefTarget?.indexOf?.('sheinlink') > -1) return
                              clickItem(item, i)
                            }}
                            vOn:baseImageMounted={baseImageMounted}
                          />
                        }
                      </InvokeApp>
                    )),
                    /* 热区组件 */
                    (showHotZone && (
                      <HotZone
                        index={props.index}
                        context={props.context}
                        language={props.context.language}
                        propData={propData}
                        sceneData={sceneData}
                        cateLinks={cateLinks}
                        getAnalysisData={getAnalysisData}
                        dialogAppendBody={isEnableFloat}
                      />)
                    )
                  ])
              }
            })()
          }
        </div>
      </div>
    )
  }
}
</script>

<style lang="less">
@vw: 375/100vw;

.static-image {
  display: flex;
  flex-direction: row;

  // 单图有热区 设置宽高百分百
  &.full-width {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &.hasBottomSpace {
    padding-bottom: 8 / @vw;
  }

  .intervalSpacing {
    margin: 0 4 / @vw;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
.mshe-z-returnTop {
  z-index: 11; /* stylelint-disable-line declaration-property-value-blacklist */
}
</style>
